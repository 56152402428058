<template>
    <div>
      <div @click="modalOpen=true">  
        <autocomplete-multiple name="Категории прайс-листа" :items.sync="selected" :select.sync="selected" textOverflow selectAllEnabled/>
      </div>

      <dialog-editor :item.sync="modalOpen" name="Категории прайс-листа" width="800" :submitText="null" content-padding-remove>
        <service-cat-explorer :selectedServiceCat.sync="selected" :tableHeight="500"/>

        <template v-slot:footerActions>
          <v-btn
              text
              @click="selected.length>0 ? selectedItemsModal=true : null"
            >Выбрано: Количество:{{selected.length}}
          </v-btn>
          <dialog-editor :item.sync="selectedItemsModal" name="Просмотр выделенных элементов" width="640" footer-hide content-padding-remove>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Название</th>
                </tr>
                </thead>
                <tbody>
                  <tr
                      v-for="(item, index) in selected"
                      :key="'selected'+index"
                  >
                    <td>{{item.id}}</td>
                    <td class="text-wrap">
                      {{item.name}}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </dialog-editor>
        </template>
      </dialog-editor>
    </div>
</template>

<script>
//import State from "@/plugins/state";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";
import ServiceCatExplorer from "@/componentsV2/base/ServiceCatExplorer.vue"
import DialogEditor from "@/componentsV2/base/DialogEditor.vue";



export default {
 components: {AutocompleteMultiple, ServiceCatExplorer, DialogEditor},
 props: ['selectedServiceCats'],
  data: () => ({
    modalOpen: false,
    selectedItemsModal:false,
    selected:[]
  }),
  watch:{
    selected(){
      this.$emit('update:selectedServiceCats',this.selected)
    }
  },
}
</script>