<template>

 <v-data-table
    v-model="selected"
    :server-items-length="count"
    :options.sync="options"
    :headers="headers"
    :items="items"
    item-key="id"
    show-select
    fixed-header
    :height="height"
    class="serviceExplorer"
    :items-per-page="50"
     :footer-props="{'items-per-page-options': [50, 100, 500]}"
  >
    <template v-slot:top>
      <v-toolbar dense flat color="#EEEEEE">

        <v-btn
          v-show="clickHistory.length>1"
          class="my-5 mx-5"
          color="primary"
          icon
          small
          elevation="2"
          @click="back()"
        >
        <v-icon dark>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <checkbox-field name="Показать удалённые" :value.sync="showDeleted"/>
   
    </v-toolbar>
    </template>


    <template v-slot:item="{ item, isSelected }">
      <tr @click="serviceCatOpen(item.id);pushToHistory(item.id);" :class="{priceDeleted: item.status==='deleted'}">
        <td>
          <v-checkbox :value="isSelected" :input-value="isItemSelected(item)" @click.stop="toggleSelection(item)" class="ma-0 pa-0" dense hide-details />
        </td>
        <td><v-icon small class="mr-2">mdi-folder-outline</v-icon> {{item.id}}</td>
        <td>{{item.name}}</td>
      </tr>
    </template>
  </v-data-table>

</template>
<script>

import {
  Entity_ServiceCat
} from "../../../EntityStoreCacheService";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";

export default {
  props: [
    'selectedServiceCat',
    'tableHeight'
  ],
  components: {
    CheckboxField
  },
  data:()=>({
    headers: [
      { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap',width:'auto'},
      { text: 'Название', value: 'name', class:'text-wrap' },
    ],
    options:{},
    count:0,
    height:'auto',
    items: [],
    selected: [],
    showDeleted: false,
    currentServiceCat:null,
    clickHistory:[],
  }),
  mounted(){
    if (typeof this.tableHeight!=='undefined')
      this.height = this.tableHeight;

    if (typeof this.selectedServiceCat!=='undefined')
      this.selected = this.selectedServiceCat;

    this.serviceCatOpen();
  },
  watch:{
    options(){
      this.serviceCatOpen(this.currentServiceCat);
    },
    showDeleted(){
      this.serviceCatOpen(this.currentServiceCat);
    },
    selected() {
      this.$emit('update:selectedServiceCat',this.selected) 
    }
  },
  methods: {
    isItemSelected(item) {
      return this.selected.some(selectedItem => selectedItem.id === item.id);
    },
    toggleSelection(item) {
      const index = this.selected.findIndex((s) => s.id === item.id);
      if (index === -1) {
        this.selected.push(item);
      } else {
        this.selected.splice(index, 1);
      }
    },
    serviceCatOpen(serviceCatId=null) {
      this.currentServiceCat = serviceCatId;
      let serviceCats = new Entity_ServiceCat()
      .selects(Entity_ServiceCat.id)
      .selects(Entity_ServiceCat.name)
      .selects(Entity_ServiceCat.status)
      .selects(Entity_ServiceCat.sort)

      if (!this.showDeleted)
        serviceCats.filterAnd([Entity_ServiceCat.entity, Entity_ServiceCat.status].join('.')+'=?', 'active')

      if (!serviceCatId)
        serviceCats.filterAnd([Entity_ServiceCat.entity, Entity_ServiceCat.serviceCat].join('.')+' is null')
      else 
        serviceCats.filterAnd([Entity_ServiceCat.entity, Entity_ServiceCat.serviceCat, Entity_ServiceCat.id].join('.')+' =?', serviceCatId)

      serviceCats.order(Entity_ServiceCat.sort, true)
      serviceCats.page(this.options.page).onPage(this.options.itemsPerPage);

      serviceCats.request((data)=>{ 
        this.count = data.count;

        //необходимо разобраться почему у дочерних категорий не работает фильтр по статусу (скорее всего из за одинакового названия serviceCat, возможно на бэке нужно переделать на parentServiceCat)
        this.items = this.showDeleted ? data.items : data.items.filter(i=>i.status==='active');

        //return to the first page if items is empty and it isn't first page
        if(this.items.length===0&&this.options.page>1)
          this.options.page = 1;
      }, (e)=>{
          console.error(e);
      })
    },
    pushToHistory(serviceCatId){
      if (this.clickHistory.length===0)
        this.clickHistory.push('root')

      this.clickHistory.push(serviceCatId);
    },
    back() {
      let previusCatId = 'root'
      if (this.clickHistory.length>1) {
        this.clickHistory.pop();
        previusCatId = this.clickHistory.at(-1);
      }

      if(previusCatId==='root')
        previusCatId = null
      
      this.serviceCatOpen(previusCatId)
    }
  }
}
</script>

<style lang="scss" scoped>
  .serviceExplorer {
    td {
      height:32px !important;
    }
  }
</style>